//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      title: 'Studioars.pl - software company sharing dedicated teams',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Software company specializes in sharing dedicated high-performance development teams in Poland / Cracow for IT companies all over the world.',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: '',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: '',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: '',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: '',
        },
      ],
    }
  },
}
